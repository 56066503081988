import React, { useState, useEffect, useRef } from "react"
import classNames from "classnames"
import { Field, ErrorMessage, useField, useFormikContext } from "formik"

import handleScrollToError from "./utils/handleScrollToError"

import styles from "./utils/form.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

/**
 ** Input field with label and error message.
 ** Supported parameters:
 **
 ** fieldProps: {
 **               'name': String,
 **               'placeholder': String,
 **               'label': String,
 **               'type': String,
 **               'onChange': Function,
 **               ...props compatible in Field Component of Formik
 **             }
 **/
const FormInput = (fieldProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const [fieldType, setFieldType] = useState(fieldProps.type)
  let { setFieldValue } = fieldProps

  const handleShowHidePassword = () => {
    if (showPassword) {
      // if current state is true/show, we need to make it false/hide
      setFieldType("password")
    } else {
      setFieldType("text")
    }
    setShowPassword(!showPassword)
  }

  const formik = useFormikContext()
  const fieldRef = useRef(null)

  const handleScrollCallback = () => {
    fieldRef.current.focus()
  }

  useEffect(() => {
    handleScrollToError({
      formikContext: formik,
      fieldName: fieldProps.name,
      callback: handleScrollCallback,
    })
  }, [formik.submitCount, formik.isValid])

  //* Function that prevents alpha and symbols
  //* if the fieldProps.type is number.
  //* This also prevents the user to input characters more than
  //* fieldProps.max (if defined).

  // We're accessing the useField props below so we can validate forms inline, on touch
  // Source: https://jaredpalmer.com/formik/docs/api/useField#usefieldname-string-fieldattributes-fieldinputprops-fieldmetaprops-fieldhelperprops
  const [] = useField(fieldProps.name)
  const isTypeNumber = fieldProps.type === "number"

  const handleOnKeyPress = (event) => {
    if (!!fieldProps.pattern && !new RegExp(fieldProps.pattern).test(event.key))
      event.preventDefault()

    if (isTypeNumber) {
      if (
        (event.key !== "." && isNaN(event.key)) ||
        (fieldProps.maxLength &&
          event.target.value.toString().length >= fieldProps.maxLength)
      )
        event.preventDefault()
    }
    if (fieldProps.onKeyPress) fieldProps.onKeyPress(event)

    if (fieldProps.isAlphaNumericOnly) {
      const inputRegex = /^[A-Za-z0-9]+$/
      if (!inputRegex.test(event.key)) {
        event.preventDefault()
      }
    }

    if (fieldType === "email") {
      const inputRegex = /^\S+$/
      if (!inputRegex.test(event.key)) {
        event.preventDefault()
      }
    }
  }

  // We're accessing the useField props below so we can validate forms inline, on touch
  // Source: https://jaredpalmer.com/formik/docs/api/useField#usefieldname-string-fieldattributes-fieldinputprops-fieldmetaprops-fieldhelperprops
  const [, { touched, error }] = useField(fieldProps.name)
  return (
    <div className={classNames(styles["formInput"], "mb-2")}>
      <label className={classNames("label has-text-weight-normal")}>
        {!!fieldProps.labelIcon && (
          <span className={`icon has-text-${fieldProps.labelIconColor}`}>
            {fieldProps.labelIcon}
          </span>
        )}
        {fieldProps.label}
        {!fieldProps.isRequired && !fieldProps.hideOptional && (
          <span className="has-text-grey is-italic"> (Optional)</span>
        )}
        {!!fieldProps.helper && (
          <span
            className={classNames(
              "help has-text-weight-normal",
              fieldProps.helperClassName
            )}
          >
            {fieldProps.helper}
          </span>
        )}
      </label>
      <div
        className={classNames("field mb-0", {
          "has-addons has-addons-right":
            fieldProps.hasAddons || fieldProps.type === "password",
        })}
      >
        {fieldProps.hasAddons && fieldProps.addonLeft && (
          <div className="control">{fieldProps.addonLeft}</div>
        )}
        <div
          className={`control ${
            fieldProps.hasAddons || fieldProps.type === "password"
              ? "is-expanded"
              : ""
          }`}
        >
          <Field {...fieldProps}>
            {({ field }) => (
              <input
                {...fieldProps}
                {...field}
                type={fieldType}
                ref={fieldRef}
                required={false}
                maxLength={fieldProps.maxLength || 50}
                className={classNames(
                  "input",
                  {
                    "is-success": touched && !error && fieldProps.isRequired,
                    "is-danger": touched && error,
                  },
                  fieldProps.className
                )}
                onKeyPress={handleOnKeyPress}
                onChange={(event) => {
                  if (
                    !fieldProps?.label?.toLowerCase().includes("email") &&
                    !fieldProps?.label?.toLowerCase().includes("password")
                  )
                    event.target.value = event.target.value.toUpperCase()

                  if (
                    !!fieldProps.separatorRegex &&
                    event.target.value?.length > 0
                  )
                    event.target.value =
                      (
                        event.target.value?.replace(
                          new RegExp(`[^0-9${fieldProps.separatorRegex}]`, "g"),
                          ""
                        ) || ""
                      )
                        .match(new RegExp(fieldProps.separatorRegex, "g"))
                        ?.join(" ") || ""

                  if (fieldProps.isNumeric && fieldProps.type === "number") {
                    let number = parseFloat(
                      event.target.value.replaceAll(",", "")
                    )

                    if (!isNaN(number)) {
                      event.target.value = number.toLocaleString()
                      setFieldValue(fieldProps.name, number)
                    }
                  }

                  const isTypeNumber = fieldProps.type === "number"
                  if (isTypeNumber) {
                    if (
                      fieldProps.max &&
                      parseFloat(event.target.value) >= fieldProps.max
                    )
                      event.target.value = fieldProps.max
                    if (
                      fieldProps.max &&
                      parseFloat(event.target.value) <= fieldProps.min
                    )
                      event.target.value = fieldProps.min
                  }

                  if (fieldProps?.excludeSpecialCharacters) {
                    const specialCharacters = /[*|:<>[\]{}`\\()!';@&#%^$+=~?/_]/
                    if (specialCharacters.test(event.target.value))
                      event.target.value = event.target.value.replace(
                        /[*|:<>[\]{}`\\()!';@&#%^$+=~?/_]/g,
                        ""
                      )
                  }

                  if (fieldProps.handleOnKeyDown) {
                    fieldProps.handleOnKeyDown(event)
                  }
                  field.onChange(event)
                  if (fieldProps?.onChange) {
                    fieldProps.onChange(event)
                  }
                }}
              />
            )}
          </Field>
        </div>
        {fieldProps.hasAddons && fieldProps.addonRight && (
          <div className="control">{fieldProps.addonRight}</div>
        )}
        {fieldProps.type === "password" ? (
          <div className="control">
            <button
              className="button"
              type="button"
              onClick={handleShowHidePassword}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
        ) : null}
      </div>
      <p className="help mt-0 mb-1 is-danger">
        <ErrorMessage name={fieldProps.name} />
      </p>
    </div>
  )
}

export default React.memo(FormInput)
